import React, { useState, useRef } from 'react';
import { saveAs } from 'file-saver';
import { franc } from 'franc-min';
import './App.css';
import { GoogleGenerativeAI, HarmCategory, HarmBlockThreshold } from "@google/generative-ai";

const apiKey = "AIzaSyBDmsHRy_YcdGxLtkFrQLv1neoAxA8qWfI";
const genAI = new GoogleGenerativeAI(apiKey);
const model = genAI.getGenerativeModel({
  model: "gemini-1.5-flash",
});

const generationConfig = {
  temperature: 1,
  topP: 0.95,
  topK: 64,
  maxOutputTokens: 8192,
  responseMimeType: "text/plain",
};

const safetySettings = [
  {
    category: HarmCategory.HARM_CATEGORY_HARASSMENT,
    threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
  },
  {
    category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
    threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
  },
  {
    category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
    threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
  },
  {
    category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
    threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
  },
];

const App = () => {
  const [code, setCode] = useState('');
  const [language, setLanguage] = useState('Shell');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [copyStatus, setCopyStatus] = useState({ input: false, output: false });
  const [tooltipText, setTooltipText] = useState({ input: 'Copy Code', output: 'Copy Code', downloadInput: 'Download', downloadOutput: 'Download' });
  const inputRef = useRef(null);
  const outputRef = useRef(null);

  const languages = [
    'ActionScript', 'Ada', 'Apex', 'Assembly', 'Awk', 'C', 'C++', 'COBOL', 'CSharp', 'Clojure', 'CoffeeScript', 'ColdFusion',
    'Crystal', 'Dart', 'Delphi', 'Eiffel', 'Elixir', 'Elm', 'Erlang', 'Fortran', 'Fsharp', 'GoLang', 'Groovy', 'Haskell', 'Haxe',
    'J#', 'Java', 'JavaScript', 'JSON', 'Julia', 'Kotlin', 'LabVIEW', 'Lisp', 'Lua', 'MATLAB', 'Nim', 'OCaml', 'Objective_C', 'PHP',
    'Pascal', 'Perl', 'Python', 'R', 'Racket', 'Ruby', 'Rust', 'SAS', 'Scala', 'Scheme', 'Shell', 'Swift', 'Tcl', 'Typescript',
    'VB.NET', 'Vala', 'YAML'
  ];

  const languageExtensions = {
    'eng': 'py', 'deu': 'js', 'fra': 'java', 'spa': 'rb', 'ita': 'cs', 'nld': 'php',
    'por': 'sh', 'rus': 'html', 'zho': 'css', 'jpn': 'json', 'kor': 'xml', 'hin': 'yaml',
    'ara': 'ts', 'tur': 'sql', 'pol': 'r', 'ukr': 'pl', 'swe': 'go', 'nor': 'swift',
    'dan': 'kt', 'fin': 'rs', 'hun': 'scala'
  };

  const getLanguageExtension = (content) => {
    const detectedLanguage = franc(content);
    return languageExtensions[detectedLanguage] || 'txt';
  };

  const handleDownload = (type) => {
    const content = type === 'input' ? code : response;
    if (!content.trim()) {
      setTooltipText(prevState => ({ ...prevState, [`download${type.charAt(0).toUpperCase() + type.slice(1)}`]: 'Nothing to download' }));
      setTimeout(() => {
        setTooltipText(prevState => ({ ...prevState, [`download${type.charAt(0).toUpperCase() + type.slice(1)}`]: 'Download' }));
      }, 2000);
      return;
    }
    const fileExtension = getLanguageExtension(content);
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    setTooltipText(prevState => ({ ...prevState, [`download${type.charAt(0).toUpperCase() + type.slice(1)}`]: 'Downloading' }));
    saveAs(blob, `${type}.${fileExtension}`);
    setTooltipText(prevState => ({ ...prevState, [`download${type.charAt(0).toUpperCase() + type.slice(1)}`]: 'Downloaded' }));
    setTimeout(() => {
      setTooltipText(prevState => ({ ...prevState, [`download${type.charAt(0).toUpperCase() + type.slice(1)}`]: 'Download' }));
    }, 2000);
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleCopy = (type) => {
    const text = type === 'input' ? code : response;
    if (!text.trim()) {
      setTooltipText(prevState => ({ ...prevState, [type]: 'Nothing to copy' }));
      setTimeout(() => {
        setTooltipText(prevState => ({ ...prevState, [type]: 'Copy Code' }));
      }, 2000);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      setCopyStatus((prevState) => ({ ...prevState, [type]: true }));
      setTooltipText((prevState) => ({ ...prevState, [type]: 'Copied' }));
      setTimeout(() => {
        setCopyStatus((prevState) => ({ ...prevState, [type]: false }));
        setTooltipText((prevState) => ({ ...prevState, [type]: 'Copy Code' }));
      }, 2000);
    });
  };

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const { selectionStart, selectionEnd, value } = target;

    if (key === 'Tab') {
      e.preventDefault();
      const before = value.substring(0, selectionStart);
      const after = value.substring(selectionEnd);
      if (e.shiftKey) {
        // Shift + Tab
        const start = before.lastIndexOf('\n') + 1;
        const lines = value.substring(start, selectionEnd).split('\n');
        const modifiedLines = lines.map(line => (line.startsWith('    ') ? line.substring(4) : line));
        const newValue = before.substring(0, start) + modifiedLines.join('\n') + after;
        setCode(newValue);
        setTimeout(() => {
          target.setSelectionRange(start, start + newValue.length - after.length);
        }, 0);
      } else {
        // Tab
        const start = before.lastIndexOf('\n') + 1;
        const lines = value.substring(start, selectionEnd).split('\n');
        const modifiedLines = lines.map(line => '    ' + line);
        const newValue = before.substring(0, start) + modifiedLines.join('\n') + after;
        setCode(newValue);
        setTimeout(() => {
          target.setSelectionRange(start, start + newValue.length - after.length);
        }, 0);
      }
    } else if (['(', '{', '[', '\'', '"', '`'].includes(key)) {
      e.preventDefault();
      const closingChar = { '(': ')', '{': '}', '[': ']', '\'': '\'', '"': '"', '`': '`' }[key];
      const newValue = value.substring(0, selectionStart) + key + closingChar + value.substring(selectionEnd);
      setCode(newValue);
      setTimeout(() => {
        target.setSelectionRange(selectionStart + 1, selectionEnd + 1);
      }, 0);
    }
  };

  const handleSubmit = async () => {
    if (!code.trim()) {
      const inputBox = inputRef.current;
      inputBox.focus();
      const tooltip = document.createElement('div');
      tooltip.className = 'input-tooltip';
      tooltip.innerHTML = '&#9888; Nothing is here to convert';
      inputBox.parentNode.insertBefore(tooltip, inputBox.nextSibling);
      setTimeout(() => {
        tooltip.remove();
      }, 2000);
      return;
    }

    setLoading(true);
    setResponse('');
    outputRef.current.scrollIntoView({ behavior: 'smooth' });

    console.log('Sending code to backend:', code);
    console.log('Selected language:', language);

    try {
      const chatSession = model.startChat({
        generationConfig,
        safetySettings,
        history: [],
      });

      const result = await chatSession.sendMessage(`Convert the following code to ${language}: \n\n${code}. Give only the code, do not give any explanation. Please give the code as complete as possible.Never give any other text except for the code`);
      let responseText = result.response.text();

      // Process the response to remove leading and trailing ```
      let responseLines = responseText.split('\n');

      if (responseLines[0].startsWith('```')) {
        responseLines = responseLines.slice(1);
      }

      if (responseLines[responseLines.length - 1].startsWith('```')) {
        responseLines = responseLines.slice(0, -1);
      }

      responseText = responseLines.join('\n');
      console.log('Processed AI response:', responseText);
      setResponse(responseText);
      setLoading(false);
    } catch (error) {
      console.error('Error generating response:', error);
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="editor-container">
        <div className="code-container">
          <div className="placeholder">
            <span className="placeholder-text">Input</span>
            <div className="buttons">
              <button className="download-button" onClick={() => handleDownload('input')}>
                <img src="https://img.icons8.com/ios-filled/50/000000/download--v1.png" alt="Download" />
                <span className="tooltip">{tooltipText.downloadInput}</span>
              </button>
              <button className="copy-button" onClick={() => handleCopy('input')}>
                <img src="https://img.icons8.com/ios-filled/50/000000/copy.png" alt="Copy" />
                <span className="tooltip">{tooltipText.input}</span>
              </button>
            </div>
          </div>
          <textarea
            ref={inputRef}
            value={code}
            onChange={handleCodeChange}
            onKeyDown={handleKeyDown}
            className="code-input"
            placeholder="Enter your code here..."
          ></textarea>
        </div>
      </div>
      <div className="controls">
        <select value={language} onChange={handleLanguageChange} className="dropdown">
          {languages.map((lang) => (
            <option key={lang} value={lang}>
              {lang}
            </option>
          ))}
        </select>
        <button onClick={handleSubmit} className="submit-button">Convert</button>
      </div>
      <div className="editor-container" ref={outputRef}>
        <div className="code-container">
          <div className="placeholder">
            <span className="placeholder-text">Output</span>
            <div className="buttons">
              <button className="download-button" onClick={() => handleDownload('output')}>
                <img src="https://img.icons8.com/ios-filled/50/000000/download--v1.png" alt="Download" />
                <span className="tooltip">{tooltipText.downloadOutput}</span>
              </button>
              <button className="copy-button" onClick={() => handleCopy('output')}>
                <img src="https://img.icons8.com/ios-filled/50/000000/copy.png" alt="Copy" />
                <span className="tooltip">{tooltipText.output}</span>
              </button>
            </div>
          </div>
          <div className="output-container">
            <textarea
              value={response}
              readOnly
              className="code-output-text"
              placeholder="Converted code will be displayed here..."
            ></textarea>
            {loading && (
              <div className="loading-overlay">
                <div className="loading-animation">
                  <div className="loading-bar"></div>
                  <div className="loading-bar"></div>
                  <div className="loading-bar"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
