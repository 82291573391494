import React from 'react';
import './Footer.css'; // Create a new CSS file for footer-specific styles

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>Creator BalaSriharsha Cheeday</p>
        <p>Google's Generative AI</p>
        <p>&copy; 2024 Spotcode.com. All rights reserved.</p>
        <p>Follow us on:
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"> Twitter</a>,
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"> Facebook</a>,
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"> LinkedIn</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
